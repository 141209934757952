import { voucherPage, releaseAdminActive, getOperatorsList } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      goodsCodeValue: [],
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        active_type: '',
        current: 1,
        fabu_state: '',
        total: 0
      },
      formInfo: {
        create_type: '固定',
        num: '',
        code: ''
      },
      operatorList: [],
      operatorIds: [],
      exportIds: '',
      checkMuneSer: [],
      user: []
    };
  },
  mounted() {
    this.getOperatorsList();
    this.getList();
    this.user = JSON.parse(localStorage.getItem('user')) || null;
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.active_type = '';
      this.pageInfo.fabu_state = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    seleType(e) {
      this.pageInfo.active_goods_type_code1 = e[0] || '';
      this.pageInfo.active_goods_type_code2 = e[1] || '';
    },
    getList() {
      voucherPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    showNotice(id) {
      this.dialogFormVisible = true;
      this.formInfo = [];
    },
    subData() {
      releaseAdminActive(this.formInfo).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
        this.dialogFormVisible = false;
      });
    }
  }
};